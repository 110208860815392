import React from "react"
import { Link } from "gatsby"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Section from "../components/Section"
import Image from "../components/Image"
import FooterCallout from "../partials/footerCallout"
import { Container, Row, Column } from "../components/Grid"
import introImage from "../images/theme/img-home-introduction.jpg"
import Resource from "../components/Resource"

import {
  confrontingComparisons,
  bullying,
} from "../partials/classroomActivities"

import comparisonsPDF from "../docs/DLB/english/DLB-1-Educator-Guide-Confronting-Comparisons-to-Build-Body-Confidence.pdf"
import comparisonsPPT from "../docs/DLB/english/DLB-1-Presentation-Confronting-Comparisons-to-Build-Body-Confidence.pptx"
import comparisonsPDFES from "../docs/DLB/spanish/DLB-1-Educator-Guide-Confronting-Comparisons-to-Build-Body-Confidence-ES.pdf"
import comparisonsPPTES from "../docs/DLB/spanish/DLB-1-Presentation-Confronting-Comparisons-to-Build-Body-Confidence-ES.pptx"
import comparisonsPDFFR from "../docs/DLB/french/Unilever-DSEP-DigitalLesson1_Comparisons-VirtualUpdate-V1.1_FR.pdf"
import comparisonsPPTFR from "../docs/DLB/french/DLB_1_Presentation_-_Confronting_Comparisons_to_Build_Body_Confidence_FR.pptx"
import comparisonsPDFPT from "../docs/DLB/portuguese/Unilever-DSEP-DigitalLesson1_Comparisons-VirtualUpdate-V1.1_[Port].pdf"
import comparisonsPPTPT from "../docs/DLB/portuguese/DLB_1_Presentation_-_Confronting_Comparisons_to_Build_Body_Confidence_PT.pptx"
import bullyingPDF from "../docs/DLB/english/DLB-2-Educator-Guide-Bullying-and-Teasing-and-Their-Effects-of-Body-Confidence.pdf"
import bullyingPDFES from "../docs/DLB/spanish/DLB-2-Educator-Guide-Bullying-and-Teasing-and-Their-Effects-of-Body-Confidence-ES.pdf"
import bullyingPDFFR from "../docs/DLB/french/Unilever-DSEP-DigitalLesson2_Bullying_Teasing-VirtualUpdate-V1.1_FR.pdf"
import bullyingPDFPT from "../docs/DLB/portuguese/Unilever-DSEP-DigitalLesson2_Bullying_Teasing-VirtualUpdate-V1.1[Port].pdf"
import bullyingPPT from "../docs/DLB/english/DLB-2-Presentation-Bullying-and-Teasing-and-Their-Effects-of-Body-Confidence.pptx"
import bullyingPPTES from "../docs/DLB/spanish/DLB-2-Presentation-Bullying-and-Teasing-and-Their-Effects-of-Body-Confidence-ES.pptx"
import bullyingPPTFR from "../docs/DLB/french/DLB_2_Presentation_-_Bullying_and_Teasing_and_Their_Effects_of_Body_Confidence_FR.pptx"
import bullyingPPTPT from "../docs/DLB/portuguese/DLB_2_Presentation_-_Bullying_and_Teasing_and_Their_Effects_of_BodyConfidence_2.pptx"

const IndexPage = () => (
  <Layout>
    <SEO title="Classroom Resources" />
    {/***************  HERO  ***************/}
    <Section className="hero hero__classroom-resources">
      <Hero
        title={
          <>
            <span className={"header-bar--yellow"}>Classroom</span>
            <span className={"header-bar--yellow"}>Resources</span>
          </>
        }
        text="Empower students to be their best selves."
        image="img-home-lead.png"
        body=""
      />
    </Section>

    {/***************  QUICKLINKS CONTAINER  ***************/}
    <Section className="quick-link-container">
      <Container>
        <p className="easy-reading-font">
          Every student deserves a chance at success. Empower your 4th and 5th
          grade students to be confident inside and out with evidence-based
          classroom resources focused on health, social-emotional learning
          (SEL), and body confidence.
        </p>
        <div className="quick-links">
          <div className="quick-links__lead quick-links__section">
            Curriculum
            <br />
            Quick Links
          </div>
          <div className="quick-links__links-container quick-links__section">
            <a href="#confronting">
              Confronting Comparisons to Build Body Confidence
            </a>
            <a href="#bullying">
              Bullying & Teasing and Their Effect on Body Confidence
            </a>
          </div>
        </div>
      </Container>
    </Section>

    {/***************  INTRODUCTION  ***************/}
    <Section className="introduction lightgray">
      <Container>
        <Row>
          <Column span={6}>
            <img src={introImage} alt={"Introduction"} />
          </Column>
          <Column span={6} className={"introduction__text"}>
            <h2>Introduction</h2>
            <p className="easy-reading-font">
              According to numerous studies, low self-esteem in adolescents can
              negatively affect their performance in school, their relationship
              with others, and their own self-perception. Access the resources
              below to teach students how comparisons and bullying can harm
              self-esteem and practice strategies for negating their influences.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  CONFRONTING COMPARISONS  ***************/}
    <Section className="confronting-comparisons toolkit" id="confronting">
      <Container>
        <h3>
          <span className="header-bar--yellow">Confronting</span>
          <span className="header-bar--yellow">Comparisons</span>
          <span className="header-bar--yellow">to</span>
          <span className="header-bar--yellow">Build</span>
          <span className="header-bar--yellow">Body</span>
          <span className="header-bar--yellow">Confidence</span>
        </h3>
        <p>
          Teach students the value of body confidence. They will explore how
          comparing themselves to others, both in person and in the media, can
          have negative consequences by investigating “appearance ideals” vs
          “healthy ideals.” They will learn about the effects of body talk and
          practice “flipping the script” before reflecting on their own feelings
          and experiences with comparisons.
        </p>

        <h2>Digital Lesson Bundle</h2>
        <Row>
          <Column span={6}>
            <Resource
              image="img-body-confidence-dlb-ppt.jpg"
              subjects={["PowerPoint"]}
              title="Confronting Comparisons to Build Body Confidence"
              grade="Grades 4-5"
              duration="3 Sessions (45 minutes each)"
              description={
                <p>
                  This ready-made PowerPoint Presentation allows educators to
                  use a visual aid as they teach students the three activities
                  included in this Digital Lesson Bundle.
                </p>
              }
              actions={[
                {
                  label: "Classroom Presentation",
                  type: "dropdown",
                  size: "PPT 17.2 MB",
                  actions: [
                    {
                      label: "English",
                      path: comparisonsPPT,
                      type: "download",
                      title:
                        "DLB 1 Presentation - Confronting Comparisons to Build Body Confidence",
                    },
                    {
                      label: "Spanish",
                      path: comparisonsPPTES,
                      type: "download",
                      title:
                        "DLB 1 Presentation - Confronting Comparisons to Build Body Confidence - Spanish",
                    },
                    {
                      label: "Portuguese",
                      path: comparisonsPPTPT,
                      type: "download",
                      title:
                        "DLB 1 Presentation - Confronting Comparisons to Build Body Confidence - Portuguese",
                    },
                    {
                      label: "French",
                      path: comparisonsPPTFR,
                      type: "download",
                      title:
                        "DLB 1 Presentation - Confronting Comparisons to Build Body Confidence - French",
                    },
                  ],
                },
              ]}
            />
          </Column>
          <Column span={6}>
            <Resource
              image="img-body-confidence-dlb-pdf.jpg"
              subjects={["PDF"]}
              title="Educator Guide"
              grade="Grades 4-5"
              duration="3 Sessions (45 minutes each)"
              description={
                <p>
                  Download the Educator Guide to learn important tips and
                  slide-by-slide procedures for delivering the PowerPoint
                  Presentation and activities.
                </p>
              }
              actions={[
                {
                  label: "Educator Guide",
                  type: "dropdown",
                  size: "PDF 2 MB",
                  actions: [
                    {
                      label: "English",
                      path: comparisonsPDF,
                      type: "download",
                      title:
                        "DLB 1 Educator Guide - Confronting Comparisons to Build Body Confidence",
                    },
                    {
                      label: "Spanish",
                      path: comparisonsPDFES,
                      type: "download",
                      title:
                        "DLB 1 Educator Guide - Confronting Comparisons to Build Body Confidence - Spanish",
                    },
                    {
                      label: "Portuguese",
                      path: comparisonsPDFPT,
                      type: "download",
                      title:
                        "DLB 1 Educator Guide - Confronting Comparisons to Build Body Confidence - Portuguese",
                    },
                    {
                      label: "French",
                      path: comparisonsPDFFR,
                      type: "download",
                      title:
                        "DLB 1 Educator Guide - Confronting Comparisons to Build Body Confidence - French",
                    },
                  ],
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  CONFRONTING COMPARISONS ACTIVITIES  ***************/}
    <Section className="confronting-comparisons-activities">
      <Container>
        <Row>
          {confrontingComparisons.map((item, i) => (
            <Column span={4} key={i}>
              <Resource label="CLASSROOM ACTIVITY" {...item} inGrid />
            </Column>
          ))}
        </Row>
      </Container>
    </Section>

    {/***************  BULLYING AND TEASING  ***************/}
    <Section className="bullying toolkit " id="bullying">
      <Container>
        <h3>
          <span className="header-bar--yellow">Bullying</span>
          <span className="header-bar--yellow">&</span>
          <span className="header-bar--yellow">Teasing</span>
          <span className="header-bar--yellow">and</span>
          <span className="header-bar--yellow">Their</span>
          <span className="header-bar--yellow">Effects</span>
          <span className="header-bar--yellow">on</span>
          <span className="header-bar--yellow">Body</span>
          <span className="header-bar--yellow">Confidence</span>
        </h3>
        <p>
          Teach students to identify body confidence and ways to help achieve
          and maintain it. Students will explore the ideas of body functionality
          and how focusing on how they feel and function vs how they look can
          increase their body confidence. Students will be introduced to
          bullying and teasing with a focus on weight-based issues and explore
          the connection between bullying and body confidence.
        </p>

        <h2>Digital Lesson Bundle</h2>
        <Row>
          <Column span={6}>
            <Resource
              image="img-bully-teasing-dlb-ppt.jpg"
              subjects={["PowerPoint"]}
              title="Bullying and Teasing and Their Effects on Body Confidence"
              grade="Grades 4-5"
              duration="3 Sessions (45 minutes each)"
              description={
                <p>
                  This ready-made PowerPoint Presentation allows educators to
                  use a visual aid as they teach students the three activities
                  included in this Digital Lesson Bundle.
                </p>
              }
              actions={[
                {
                  label: "Classroom Presentation",
                  type: "dropdown",
                  size: "PPT 14.8 MB",
                  actions: [
                    {
                      label: "English",
                      type: "download",
                      path: bullyingPPT,
                      title:
                        "DLB 2 Presentation - Bullying and Teasing and Their Effects of Body Confidence",
                    },
                    {
                      label: "Spanish",
                      type: "download",
                      path: bullyingPPTES,
                      title:
                        "DLB 2 Presentation - Bullying and Teasing and Their Effects of Body Confidence - Spanish",
                    },
                    {
                      label: "Portuguese",
                      type: "download",
                      path: bullyingPPTPT,
                      title:
                        "DLB 2 Presentation - Bullying and Teasing and Their Effects of Body Confidence - Portuguese",
                    },
                    {
                      label: "French",
                      type: "download",
                      path: bullyingPPTFR,
                      title:
                        "DLB 2 Presentation - Bullying and Teasing and Their Effects of Body Confidence - French",
                    },
                  ],
                },
              ]}
            />
          </Column>
          <Column span={6}>
            <Resource
              image="img-bully-teasing-dlb-pdf.jpg"
              subjects={["PDF"]}
              title="Educator Guide"
              grade="Grades 4-5"
              duration="3 Sessions (45 minutes each)"
              description={
                <p>
                  Download the Educator Guide to learn important tips and
                  slide-by-slide procedures for delivering the PowerPoint
                  Presentation and activities.
                </p>
              }
              actions={[
                {
                  label: "Educator Guide",
                  size: "PDF 2 MB",
                  type: "dropdown",
                  actions: [
                    {
                      label: "English",
                      path: bullyingPDF,
                      type: "download",
                      title:
                        "DLB 2 Educator Guide - Bullying and Teasing and Their Effects of Body Confidence",
                    },
                    {
                      label: "Spanish",
                      path: bullyingPDFES,
                      type: "download",
                      title:
                        "DLB 2 Educator Guide - Bullying and Teasing and Their Effects of Body Confidence - Spanish",
                    },
                    {
                      label: "Portuguese",
                      path: bullyingPDFPT,
                      type: "download",
                      title:
                        "DLB 2 Educator Guide - Bullying and Teasing and Their Effects of Body Confidence - Portuguese",
                    },
                    {
                      label: "French",
                      path: bullyingPDFFR,
                      type: "download",
                      title:
                        "DLB 2 Educator Guide - Bullying and Teasing and Their Effects of Body Confidence - French",
                    },
                  ],
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  BULLYING AND TEASING ACTIVITIES  ***************/}
    <Section className="bullying-activities">
      <Container>
        <Row>
          {bullying.map((item, i) => (
            <Column span={4} key={i}>
              <Resource label="CLASSROOM ACTIVITY" {...item} inGrid />
            </Column>
          ))}
        </Row>
      </Container>
    </Section>

    {/***************  EXPLORE ADDITIONAL RESOURCES ***************/}
    <Section className={"squiggle"}>
      <Container>
        <h3>Explore our Resources</h3>
        <Row>
          <Column span={5} className={"is-offset-1 resource"}>
            <Image
              className={"resource-image"}
              filename={"img-promo-master-class.jpg"}
            />
            <h4 className={"bold-text"}>Master Class Series</h4>
            <p>Learn tools to implement body confidence in your classroom.</p>
            <Link to={"/master-class"}>Learn More</Link>
          </Column>
          <Column span={5} className={"resource"}>
            <Image
              className={"resource-image"}
              filename={"img-promo-vft.png"}
            />
            <h4 className={"bold-text"}>Virtual Field Trip</h4>
            <p>
              Help students learn to appreciate their bodies, get smarter about
              images they see online, and build strategies to fight weight-based
              bullying and teasing.
            </p>
            <Link to={"/virtual-field-trip/"}>Learn More</Link>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  ABOVE FOOTER CALLOUT ***************/}
    <Section className=" midgray-background body-confidence-fact--vft">
      <Container>
        <Row>
          <Column span={7}>
            <p>
              By age 9, children begin to be able to view themselves as
              individual entities and how their bodies interact with the world.
            </p>
            <br />
            <p>
              - <em>British Journal of Health Psychology</em>
            </p>
          </Column>
          <Column
            span={4}
            className={"is-offset-1 body-confidence-fact__container"}
          >
            <div className={"body-confidence-fact__container__image"}>
              <Image filename={"img-subfooter-2.png"} className={""} />
            </div>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  FOOTER CALLOUT ***************/}
    <FooterCallout />
  </Layout>
)

export default IndexPage
