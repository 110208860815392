import React from "react"
import appearanceAndIdeals from "../docs/classroom-activities/english/Classroom-Activity-1-1-Appearance-Ideals-in-the-Media.pdf"
import appearanceAndIdealsES from "../docs/classroom-activities/spanish/Classroom-Activity-1-1-Appearance-Ideals-in-the-Media-ES.pdf"
import appearanceAndIdealsFR from "../docs/classroom-activities/french/Unilever-DSEP-ClassroomActivity-Virtual-1.1-AppearanceIdealsintheMedia_FR.pdf"
import appearanceAndIdealsPT from "../docs/classroom-activities/portuguese/Unilever-DSEP-ClassroomActivity-Virtual-1.1-AppearanceIdealsintheMedia[Port].pdf"
import bodyTalk from "../docs/classroom-activities/english/Classroom-Activity-1-2-Body-Talk.pdf"
import bodyTalkES from "../docs/classroom-activities/spanish/Classroom-Activity-1-2-Body-Talk-ES.pdf"
import bodyTalkFR from "../docs/classroom-activities/french/Unilever-DSEP-ClassroomActivity-Virtual-1.2-BodyTalk_FR.pdf"
import bodyTalkPT from "../docs/classroom-activities/portuguese/Unilever-DSEP-ClassroomActivity-Virtual-1.2-BodyTalk[Port].pdf"
import competeCompare from "../docs/classroom-activities/english/Classroom-Activity-1-3-Competing-and-Comparing-Looks.pdf"
import competeCompareES from "../docs/classroom-activities/spanish/Classroom-Activity-1-3-Competing-and-Comparing-Looks-ES.pdf"
import competeCompareFR from "../docs/classroom-activities/french/Unilever-DSEP-ClassroomActivity-Virtual-1.3-CompetingandComparingLooks_FR.pdf"
import competeComparePT from "../docs/classroom-activities/portuguese/Unilever-DSEP-ClassroomActivity-Virtual-1.3-CompetingandComparingLooks[Port].pdf"
import letsTalk from "../docs/classroom-activities/english/Classroom-Activity-1-4-Lets-Talk-About-Something-Else.pdf"
import letsTalkES from "../docs/classroom-activities/spanish/Classroom-Activity-1-4-Lets-Talk-About-Something-Else-ES.pdf"
import letsTalkFR from "../docs/classroom-activities/french/Unilever-DSEP-ClassroomActivity-Virtual-1.4-LetsTalkAboutSomethingElse_FR.pdf"
import letsTalkPT from "../docs/classroom-activities/portuguese/Unilever-DSEP-ClassroomActivity-Virtual-1.4-LetsTalkAboutSomethingElse[Port].pdf"
import positiveAffirmations from "../docs/classroom-activities/english/Classroom-Activity-1-5-Positive-Affirmations.pdf"
import positiveAffirmationsES from "../docs/classroom-activities/spanish/Classroom-Activity-1-5-Positive-Affirmations-ES.pdf"
import positiveAffirmationsFR from "../docs/classroom-activities/french/Unilever-DSEP-ClassroomActivity-Virtual-1.5-PositiveThoughts_FR.pdf"
import positiveAffirmationsPT from "../docs/classroom-activities/portuguese/Unilever-DSEP-ClassroomActivity-Virtual-1.5-PositiveThoughts[Port].pdf"
import whatsFunction from "../docs/classroom-activities/english/Classroom-Activity-2-1-Whats-Your-Function.pdf"
import whatsFunctionES from "../docs/classroom-activities/spanish/Classroom-Activity-2-1-Whats-Your-Function-ES.pdf"
import whatsFunctionFR from "../docs/classroom-activities/french/Unilever-DSEP-ClassroomActivity-Virtual-2.1-WhatsYourFunction_FR.pdf"
import whatsFunctionPT from "../docs/classroom-activities/portuguese/Unilever-DSEP-ClassroomActivity-Virtual-2.1-WhatsYourFunction[Port].pdf"
import weightBullying from "../docs/classroom-activities/english/Classroom-Activity-2-2-Weight-based-bullying-What-can-I-do.pdf"
import weightBullyingES from "../docs/classroom-activities/spanish/Classroom-Activity-2-2-Weight-based-bullying-What-can-I-do-ES.pdf"
import weightBullyingFR from "../docs/classroom-activities/french/Unilever-DSEP-ClassroomActivity-Virtual-2.2-WeightBasedBullying_FR.pdf"
import weightBullyingPT from "../docs/classroom-activities/portuguese/Unilever-DSEP-ClassroomActivity-Virtual-2.2-WeightBasedBullying[Port].pdf"
import mediaInfluence from "../docs/classroom-activities/english/Classroom-Activity-2-3-Media-Whats-the-Influence.pdf"
import mediaInfluenceES from "../docs/classroom-activities/spanish/Classroom-Activity-2-3-Media-Whats-the-Influence-ES.pdf"
import mediaInfluenceFR from "../docs/classroom-activities/french/Unilever-DSEP-ClassroomActivity-Virtual-2.3-Media-WhatsTheInfluence_FR.pdf"
import mediaInfluencePT from "../docs/classroom-activities/portuguese/Unilever-DSEP-ClassroomActivity-Virtual-2.3-Media-WhatsTheInfluence[Port].pdf"
import bullyingInSchool from "../docs/classroom-activities/english/Classroom-Activity-2-4-Bullying-in-Our-School-What-Can-We-Do.pdf"
import bullyingInSchoolES from "../docs/classroom-activities/spanish/Classroom-Activity-2-4-Bullying-in-Our-School-What-Can-We-Do-ES.pdf"
import bullyingInSchoolFR from "../docs/classroom-activities/french/Unilever-DSEP-ClassroomActivity-Virtual-2.4-BullyinginourSchool_FR.pdf"
import bullyingInSchoolPT from "../docs/classroom-activities/portuguese/Unilever-DSEP-ClassroomActivity-Virtual-2.4-BullyinginourSchool[Port].pdf"
import exitAndRefusal from "../docs/classroom-activities/english/Classroom-Activity-2-5-Exit-and-Refusal-Strategies.pdf"
import exitAndRefusalES from "../docs/classroom-activities/spanish/Classroom-Activity-2-5-Exit-and-Refusal-Strategies-ES.pdf"
import exitAndRefusalFR from "../docs/classroom-activities/french/Unilever-DSEP-ClassroomActivity-Virtual-2.5-ExitAndRefusalStrategies_FR.pdf"
import exitAndRefusalPT from "../docs/classroom-activities/portuguese/Unilever-DSEP-ClassroomActivity-Virtual-2.5-ExitAndRefusalStrategies[Port].pdf"

export const confrontingComparisons = [
  {
    title: "Appearance Ideals in the Media",
    // subject: 'subject type',
    duration: "45 minutes",
    grade: "Grades 4-5",
    description:
      'In this activity, students will view the Media & Celebrities video & investigate how television, magazines, movies, and ads use appeal strategies and "appearance ideals" to influence the decisions they make.',
    image: "img_activity_appearance_ideals.jpg",
    actions: [
      {
        label: "Watch Video",
        type: "dropdown",
        actions: [
          {
            label: "English",
            type: "video",
            youtube_id: "eNYnkWtOVHA",
            title: "Appearance Ideals in the Media",
          },
          {
            label: "Spanish",
            type: "video",
            youtube_id: "sqoKhbvS7k4",
            title: "Appearance Ideals in the Media",
          },
          {
            label: "Portuguese",
            type: "external",
            url: 'https://www.stevenuniverseselfesteem.com.br/',
            title: "Appearance Ideals in the Media",
          },
          {
            label: "French",
            type: "external",
            url: 'https://www.stevenuniverseselfesteem.fr',
            title: "Appearance Ideals in the Media",
          },
        ],
      },
      {
        label: "Classroom Activities",
        type: "dropdown",
        size: "PDF 1.6 MB",
        actions: [
          {
            label: "English",
            path: appearanceAndIdeals,
            type: "download",
            title: "Classroom Activity - 1.1 Appearance Ideals in the Media",
          },
          {
            label: "Spanish",
            path: appearanceAndIdealsES,
            type: "download",
            title:
              "Classroom Activity - 1.1 Appearance Ideals in the Media - Spanish",
          },
          {
            label: "Portuguese",
            path: appearanceAndIdealsPT,
            type: "download",
            title:
              "Classroom Activity - 1.1 Appearance Ideals in the Media - Portuguese",
          },
          {
            label: "French",
            path: appearanceAndIdealsFR,
            type: "download",
            title:
              "Classroom Activity - 1.1 Appearance Ideals in the Media - French",
          },
        ],
      },
    ],
  },
  {
    title: "Body Talk",
    // subject: 'subject type',
    image: "img_activity_body_talk.jpg",
    grade: "Grades 4-5",
    duration: "45 minutes",
    description:
      "In this activity, students will view the Body Talk video before working with a partner" +
      " to answer questions, including answering how negative body talk can be harmful and investigating how they can change the conversation.",
    actions: [
      {
        label: "Watch Video",
        type: "dropdown",
        actions: [
          {
            label: "English",
            type: "video",
            youtube_id: "KdwhTKOyu5E",
            title: "Body Talk",
          },
          {
            label: "Spanish",
            type: "video",
            youtube_id: "5NapccA_eM8",
            title: "Body Talk",
          },
          {
            label: "Portuguese",
            type: "external",
            url: 'https://www.stevenuniverseselfesteem.com.br/',
            title: "Body Talk",
          },
          {
            label: "French",
            type: "external",
            url: 'https://www.stevenuniverseselfesteem.fr',
            title: "Body Talk",
          },
        ],
      },
      {
        label: "Classroom Activities",
        size: "PDF 1.6 MB",
        type: "dropdown",
        actions: [
          {
            label: "English",
            path: bodyTalk,
            type: "download",
            title: "Classroom Activity - 1.2 Body Talk",
          },
          {
            label: "Spanish",
            path: bodyTalkES,
            type: "download",
            title: "Classroom Activity - 1.2 Body Talk - Spanish",
          },
          {
            label: "Portuguese",
            path: bodyTalkPT,
            type: "download",
            title: "Classroom Activity - 1.2 Body Talk - Portuguese",
          },
          {
            label: "French",
            path: bodyTalkFR,
            type: "download",
            title: "Classroom Activity - 1.2 Body Talk - French",
          },
        ],
      },
    ],
  },
  {
    image: "img_activity_competing_comparing.jpg",
    // subject: 'subject type',
    title: "Competing and Comparing Looks",
    grade: "Grades 4-5",
    duration: "45 minutes",
    description: (
      <span>
        In this activity, students will respond to statements regarding
        competing and comparing looks in a “four corners” format. Then, they
        will view the <em>Competing and Comparing Looks</em> video and
        brainstorm nine things they love about themselves with a focus on what
        makes them unique.
      </span>
    ),
    actions: [
      {
        label: "Watch Video",
        type: "dropdown",
        actions: [
          {
            label: "English",
            type: "video",
            youtube_id: "3GPkw0hZPE8",
            title: "Competing and Comparing Looks",
          },
          {
            label: "Spanish",
            type: "video",
            youtube_id: "3mfMrPurFIQ",
            title: "Competing and Comparing Looks",
          },
          {
            label: "Portuguese",
            type: "external",
            url: 'https://www.stevenuniverseselfesteem.com.br/',
            title: "Competing and Comparing Looks",
          },
          {
            label: "French",
            type: "external",
            url: 'https://www.stevenuniverseselfesteem.fr',
            title: "Competing and Comparing Looks",
          },
        ],
      },
      {
        label: "Classroom Activities",
        size: "PDF 1.5 MB",
        type: "dropdown",
        actions: [
          {
            label: "English",
            path: competeCompare,
            type: "download",
            title: "Classroom Activity - 1.3 Competing and Comparing Looks",
          },
          {
            label: "Spanish",
            path: competeCompareES,
            type: "download",
            title:
              "Classroom Activity - 1.3 Competing and Comparing Looks - Spanish",
          },
          {
            label: "Portuguese",
            path: competeComparePT,
            type: "download",
            title:
              "Classroom Activity - 1.3 Competing and Comparing Looks - Portuguese",
          },
          {
            label: "French",
            path: competeCompareFR,
            type: "download",
            title:
              "Classroom Activity - 1.3 Competing and Comparing Looks - French",
          },
        ],
      },
    ],
  },
  {
    image: "img_activity_lets_talk_about.jpg",
    // subject: 'subject type',
    title: "Let’s Talk About Something Else",
    duration: "45 minutes",
    grade: "Grades 4-5",
    description:
      "In this activity, students will investigate what percentage of their peers’" +
      " conversations revolve around appearances and practice ways to redirect the conversations using roleplay.",
    actions: [
      {
        label: "Classroom Activities",
        size: "PDF 2.8 MB",
        type: "dropdown",
        actions: [
          {
            label: "English",
            path: letsTalk,
            type: "download",
            title: "Classroom Activity - 1.4 Let’s Talk About Something Else",
          },
          {
            label: "Spanish",
            path: letsTalkES,
            type: "download",
            title:
              "Classroom Activity - 1.4 Let’s Talk About Something Else - Spanish",
          },
          {
            label: "Portuguese",
            path: letsTalkPT,
            type: "download",
            title:
              "Classroom Activity - 1.4 Let’s Talk About Something Else - Portuguese",
          },
          {
            label: "French",
            path: letsTalkFR,
            type: "download",
            title:
              "Classroom Activity - 1.4 Let’s Talk About Something Else - French",
          },
        ],
      },
    ],
  },
  {
    image: "img_activity_positive_affirmations.jpg",
    // subject: 'subject type',
    title: "Positive Affirmations",
    duration: "45 minutes",
    grade: "Grades 4-5",
    description:
      "In this activity, students will learn about body dissatisfaction and positive affirmations before working with peers to create posters or cards featuring each student’s positive qualities, skills, and characteristics.",
    actions: [
      {
        label: "Classroom Activities",
        size: "PDF 3.1 MB",
        type: "dropdown",
        actions: [
          {
            label: "English",
            path: positiveAffirmations,
            type: "download",
            title: "Classroom Activity - 1.5 Positive Affirmations",
          },
          {
            label: "Spanish",
            path: positiveAffirmationsES,
            type: "download",
            title: "Classroom Activity - 1.5 Positive Affirmations - Spanish",
          },
          {
            label: "Portuguese",
            path: positiveAffirmationsPT,
            type: "download",
            title: "Classroom Activity - 1.5 Positive Affirmations - Portuguese",
          },
          {
            label: "French",
            path: positiveAffirmationsFR,
            type: "download",
            title: "Classroom Activity - 1.5 Positive Affirmations - French",
          },
        ],
      },
    ],
  },
]

export const bullying = [
  {
    title: "What’s Your Function?!",
    // subject: 'subject type',
    duration: "45 minutes",
    grade: "Grades 4-5",
    description: (
      <span>
        In this activity, students will watch the <em>Body Functionality</em>{" "}
        video before acknowledging the things that their bodies do every day,
        from the most basic functions to special skills.
      </span>
    ),
    image: "img-activity-whats-your-function.jpg",
    actions: [
      {
        label: "Watch Video",
        type: "dropdown",
        actions: [
          {
            label: "English",
            type: "video",
            youtube_id: "IsHZOsRpNT8",
            title: "What’s Your Function?!",
          },
          {
            label: "Spanish",
            type: "video",
            youtube_id: "Jt9KKKbJkAU",
            title: "What’s Your Function?!",
          },
          {
            label: "Portuguese",
            type: "external",
            url: 'https://www.stevenuniverseselfesteem.com.br/',
            title: "What’s Your Function?!",
          },
          {
            label: "French",
            type: "external",
            url: 'https://www.stevenuniverseselfesteem.fr',
            title: "What’s Your Function?!",
          },
        ],
      },
      {
        label: "Classroom Activities",
        size: "PDF 1.4 MB",
        type: "dropdown",
        actions: [
          {
            label: "English",
            path: whatsFunction,
            type: "download",
            title: "Classroom Activity - 2.1 What’s Your Function",
          },
          {
            label: "Spanish",
            path: whatsFunctionES,
            type: "download",
            title: "Classroom Activity - 2.1 What’s Your Function - Spanish",
          },
          {
            label: "Portuguese",
            path: whatsFunctionPT,
            type: "download",
            title: "Classroom Activity - 2.1 What’s Your Function - Portuguese",
          },
          {
            label: "French",
            path: whatsFunctionFR,
            type: "download",
            title: "Classroom Activity - 2.1 What’s Your Function - French",
          },
        ],
      },
    ],
  },
  {
    title: "Weight-based bullying: What can I do?",
    // subject: 'subject type',
    image: "img-activity-weight-based-bullying.jpg",
    grade: "Grades 4-5",
    duration: "45 minutes",
    description:
      "In this activity, the teacher will lead students through a close reading of an informational text on weight-based bullying and teasing and brainstorm exit strategies for when bullying and teasing occurs.",
    actions: [
      {
        label: "Watch Video",
        type: "dropdown",
        actions: [
          {
            label: "English",
            type: "video",
            youtube_id: "61AqBH0bqlQ",
            title: "Weight-based bullying: What can I do?",
          },
          {
            label: "Spanish",
            type: "video",
            youtube_id: "StLcvnYCiqE",
            title: "Weight-based bullying: What can I do?",
          },
          {
            label: "Portuguese",
            type: "external",
            url: 'https://www.stevenuniverseselfesteem.com.br/',
            title: "Weight-based bullying: What can I do?",
          },
          {
            label: "French",
            type: "external",
            url: 'https://www.stevenuniverseselfesteem.fr',
            title: "Weight-based bullying: What can I do?",
          },

        ],
      },
      {
        label: "Classroom Activities",
        size: "PDF 2.8 MB",
        type: "dropdown",
        actions: [
          {
            label: "English",
            path: weightBullying,
            type: "download",
            title:
              "Classroom Activity - 2.2 Weight-based bullying What can I do",
          },
          {
            label: "Spanish",
            path: weightBullyingES,
            type: "download",
            title:
              "Classroom Activity - 2.2 Weight-based bullying What can I do - Spanish",
          },
          {
            label: "Portuguese",
            path: weightBullyingPT,
            type: "download",
            title:
              "Classroom Activity - 2.2 Weight-based bullying What can I do - Portuguese",
          },
          {
            label: "French",
            path: weightBullyingFR,
            type: "download",
            title:
              "Classroom Activity - 2.2 Weight-based bullying What can I do - French",
          },
        ],
      },
    ],
  },
  {
    image: "img-activity-media-influence.jpg",
    // subject: 'subject type',
    title: "Media: What’s the Influence?",
    grade: "Grades 4-5",
    duration: "45 minutes",
    description:
      "In this activity, students will participate in a classroom-based social experiment that mimics social media usage and demonstrates the “herding effect,” the influence that the actions of others have on one’s decisions.",
    actions: [
      {
        label: "Watch Video",
        type: "dropdown",
        actions: [
          {
            label: "English",
            type: "video",
            youtube_id: "yCNjiU12gf8",
            title: "Media: What’s the Influence?",
          },
          {
            label: "Spanish",
            type: "video",
            youtube_id: "Iim38EtU_GI",
            title: "Media: What’s the Influence?",
          },
          {
            label: "Portuguese",
            type: "external",
            url: 'https://www.stevenuniverseselfesteem.com.br/',
            title: "Media: What’s the Influence?",
          },
          {
            label: "French",
            type: "external",
            url: 'https://www.stevenuniverseselfesteem.fr',
            title: "Media: What’s the Influence?",
          },
        ],
      },
      {
        label: "Classroom Activities",
        size: "PDF 5.2 MB",
        type: "dropdown",
        actions: [
          {
            label: "English",
            path: mediaInfluence,
            type: "download",
            title: "Classroom Activity - 2.3 Media What’s the Influence",
          },
          {
            label: "Spanish",
            path: mediaInfluenceES,
            type: "download",
            title:
              "Classroom Activity - 2.3 Media What’s the Influence - Spanish",
          },
          {
            label: "Portuguese",
            path: mediaInfluencePT,
            type: "download",
            title:
              "Classroom Activity - 2.3 Media What’s the Influence - Portuguese",
          },
          {
            label: "French",
            path: mediaInfluenceFR,
            type: "download",
            title:
              "Classroom Activity - 2.3 Media What’s the Influence - French",
          },
        ],
      },
    ],
  },
  {
    image: "img-activity-bullying-in-school.jpg",
    // subject: 'subject type',
    title: "Bullying in Our School: What Can We Do?",
    duration: "45 minutes",
    grade: "Grades 4-5",
    description:
      "In this activity, students will discuss their school’s bullying policy and make suggestions for revisions to include the issues of weight-based bullying and teasing.",
    actions: [
      {
        label: "Classroom Activities",
        size: "PDF 2.7 MB",
        type: "dropdown",
        actions: [
          {
            label: "English",
            path: bullyingInSchool,
            type: "download",
            title:
              "Classroom Activity - 2.4 Bullying in Our School What Can We Do",
          },
          {
            label: "Spanish",
            path: bullyingInSchoolES,
            type: "download",
            title:
              "Classroom Activity - 2.4 Bullying in Our School What Can We Do - Spanish",
          },
          {
            label: "Portuguese",
            path: bullyingInSchoolPT,
            type: "download",
            title:
              "Classroom Activity - 2.4 Bullying in Our School What Can We Do - Portuguese",
          },
          {
            label: "French",
            path: bullyingInSchoolFR,
            type: "download",
            title:
              "Classroom Activity - 2.4 Bullying in Our School What Can We Do - French",
          },
        ],
      },
    ],
  },
  {
    image: "img-activity-exit-and-refusal.jpg",
    // subject: 'subject type',
    title: "Exit and Refusal Strategies",
    duration: "45 minutes",
    grade: "Grades 4-5",
    description:
      "In this activity, students will engage in a role play activity to practice using" +
      " specific strategies to address bullying and teasing.",
    actions: [
      {
        label: "Classroom Activities",
        size: "PDF 1.6 MB",
        type: "dropdown",
        actions: [
          {
            label: "English",
            path: exitAndRefusal,
            type: "download",
            title: "Classroom Activity - 2.5 Exit and Refusal Strategies",
          },
          {
            label: "Spanish",
            path: exitAndRefusalES,
            type: "download",
            title:
              "Classroom Activity - 2.5 Exit and Refusal Strategies - Spanish",
          },
          {
            label: "Portuguese",
            path: exitAndRefusalPT,
            type: "download",
            title:
              "Classroom Activity - 2.5 Exit and Refusal Strategies - Portuguese",
          },
          {
            label: "French",
            path: exitAndRefusalFR,
            type: "download",
            title:
              "Classroom Activity - 2.5 Exit and Refusal Strategies - French",
          },
        ],
      },
    ],
  },
]
